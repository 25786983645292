body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'League Spartan', sans-serif;
  background-color: #F7F9FB;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw; /* Ensure it stretches across the full viewport width */
  position: relative;
  overflow: hidden; /* Hide any overflow from the pseudo-elements */
}

.login-container::before {
  content: "";
  background: url('../src/images/logo-transparent.png') no-repeat center center;
  background-size: 100%;
  opacity: 0.03;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0; /* Background layer */
}

.login-container::after {
  content: "";
  background: url('../src/images/logo-transparent.png') no-repeat center center;
  background-size: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Stretch to the full container width */
  height: 100%; /* Stretch to the full container height */
  z-index: 1; /* Between background and login box */
}

.login-box {
  background: rgba(255, 255, 255, 0.98);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 400px;
  width: 100%;
  z-index: 2; /* Ensure it stays above the logo */
  text-align: center;
}


.logo-text {
  width: 100%;
  margin-bottom: 2rem;
}

.MuiTextField-root {
  margin-bottom: 1.5rem;  /* Space between inputs */
  background-color: #EFF4F7;
  border-radius: 8px;
}

.MuiButton-containedPrimary {
  background-color: #21A0A4;
  font-weight: bold;
  padding: 12px 0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.MuiButton-containedPrimary:hover {
  background-color: #1B8E92;
}

.dialog-title {
  color: #b71c1c;
  font-weight: bold;
}

/* Autofill fix */
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #EFF4F7 inset !important;
  -webkit-text-fill-color: #000 !important;
}

.sign-in-btn, .nav-links button {
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: transparent;
  color: #21A0A4;
  border: 2px solid #21A0A4;
  padding: 0.4rem 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  white-space: nowrap;
}

.sign-in-btn:hover, .nav-links button:hover {
  background-color: #21A0A4;
  color: #fff;
}