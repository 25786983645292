.map {
  display: flex;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);  /* Ensure the container covers the full viewport */
  box-sizing: border-box;
  font-family: 'League Spartan', sans-serif;
  margin-top: 40px;  /* Space for navbar */
}
.hydrants-dashboard-container{

  padding-left: 20px;
}

/* General Card Styling */
.freguesia-card,
.hydrant-table-card,
.overview-card {
  margin-top: 20px;  /* Space for navbar */
  background-color: #ffffff !important;
  color: rgb(79, 79, 79) !important;
  font-family: 'League Spartan', sans-serif !important;
  border-radius: 12px;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.freguesia-card:hover,
.hydrant-table-card:hover,
.overview-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(33, 160, 164, 0.2);
}

/* Card Titles */
.card-title {
  color: rgb(79, 79, 79) !important;
  font-weight: 700;
  font-size: 1.5rem;  /* Adjusted size for card headers */
  margin-bottom: 10px;
}

/* Subtitles for Parishes and Overview */
.card-subtitle {
  color: #21A0A4 !important;
  font-weight: 600;
  font-size: 1.2rem;  /* Medium size for subheadings */
  margin: 10px 0;
}

/* Left Section - Freguesia List */
.freguesia-card {
  height: 97%;
}

.freguesia-list {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  padding-right: 10px;
}

/* Hydrant Links */
.hydrant-link {
  text-decoration: none;
  font-size: 1rem;  /* Regular size for links */
  display: block;  /* Ensures links are on separate lines */
  margin-bottom: 4px;
  color: #009BF4;
  font-weight: bold;
  font-family: 'League Spartan', sans-serif;
}

.hydrant-link:hover {
  text-decoration: underline;
}

/* Hydrant Table */
.hydrant-table-card {
  margin-top: 20px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: #ffffff;
  color: rgb(79, 79, 79);
}


.table-header img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Table Header */
.hydrant-table-card .MuiTableHead-root {
  background-color: #ffffff !important;
}

.hydrant-table-card .MuiTableCell-head {
  color: rgb(79, 79, 79) !important;
  font-weight: 700 !important;
  font-size: 1rem !important;  /* Clear distinction for table headers */
  border-bottom: 2px solid #e0e0e0 !important;
}

/* Table Body */
.hydrant-table-card .MuiTableBody-root .MuiTableCell-root {
  font-size: 0.8rem !important;
  color: #333333 !important;
}

/* Sorting Icon */
.hydrant-table-card .MuiIconButton-root img {
  width: 14px !important;
  height: 14px !important;
}

/* Overview Card */
.overview-card {

}

.overview-card .card-subtitle{
  color: rgb(79, 79, 79) !important;
  font-weight: 700 !important;
  font-size: 1rem !important;  /* Clear distinction for table headers */
  padding-bottom: 10px;
  padding-top: 10px;
}

.overview-card ul {
  list-style-type: none;
  padding: 0;
}

.overview-card li {
  margin-bottom: 5px;
  color: rgb(79, 79, 79);
  font-size: 1rem;
}
