.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 21px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;  /* Ensure full vertical space */
  padding-left: 0px; /* Adjust this to align vertically with the sidebar */
}

.logo-image {
  height: 65px;
  width: auto;
  object-fit: contain;
}


/* Page Title Styling */
.navbar .page-title {
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  color: #4f4f4f;
  margin-left: 50px; /* Align the title after the sidebar width */
  flex-grow: 1; /* Ensure the title occupies the available space */
  text-align: left; /* Align the text to the left */
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: #4f4f4f;
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #21A0A4;
}

/* Sign In/Out Button */
.sign-in-btn, .nav-links button {
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: transparent;
  color: #21A0A4;
  border: 2px solid #21A0A4;
  padding: 0.4rem 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  white-space: nowrap;
}

.sign-in-btn:hover, .nav-links button:hover {
  background-color: #21A0A4;
  color: #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar {
    padding: 0.8rem 1.5rem;
  }

  .nav-links {
    gap: 1rem;
  }

  .sign-in-btn {
    padding: 0.3rem 0.8rem;
  }

  .navbar .page-title {
    margin-left: 0;  /* Remove margin for small screens */
    text-align: center; /* Center align the title on mobile */
  }
}
