.dashboard-container {
  padding: 20px;
}

.dashboard-title {
  color: #4F4F4F;
  font-family: 'League Spartan', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.hydrant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
/* Search Bar Styling */
.search-bar input {
  width: 100%;
  padding: 10px;
  border: 2px solid #ddd;  /* Default border */
  border-radius: 12px;
  outline: none;  /* Remove default browser outline */
  font-family: 'League Spartan', sans-serif;
  font-size: 1rem;
  color: #4f4f4f;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

/* Change border color on focus (when clicked) */
.search-bar input:focus {
  border: 2px solid #21A0A4;  /* Your desired color */
}

.hydrant-id a {
  text-decoration: none;
  color: #009BF4;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: 'League Spartan', sans-serif;
}

.hydrant-info {
  color: #4F4F4F;
  margin-bottom: 8px;
  font-size: 0.95rem;
}

.map-link {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.dashboard-container {
  padding: 20px;
}

/* Align Search Bar and Filter */
.dashboard-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Unified styling for Search Bar and Filter */
.search-bar, .filter-dropdown {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border 0.3s ease;
}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.blinking {
  animation: blink 1s infinite;
}

/* Hover effect with #21A0A4 */
.search-bar:hover, .filter-dropdown:hover {
  border: 2px solid #21A0A4;
  box-shadow: 0 4px 8px rgba(33, 160, 164, 0.2);
}

/* Adjusting Search Bar Size */
.search-bar {
  width: 60%;  /* Adjust to fit design */
  padding: 10px;
  display: flex;
  align-items: center;
}

/* Filter Dropdown Styling */
.filter-dropdown {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  min-width: 200px;
}

/* Dropdown Text Styling */
.MuiSelect-select {
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  color: #4f4f4f;
}

.MuiSelect-icon {
  color: #21A0A4;  /* Icon color */
}

/* Hydrant Card Styling */
.hydrant-card {
  border-radius: 12px;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.hydrant-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(33, 160, 164, 0.2);
}
.hydrant-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.hydrant-header .hydrant-id {
  font-weight: bold;
  color: #009BF4;
  margin-left: 5px;
  flex-grow: 1;
  display: flex;
  align-items: center;  /* Aligns the name with other text */
}

.hydrant-info {
  margin-bottom: 4px;
  color: #4f4f4f;
}

.google-maps-link {
  margin-top: 10px;
  margin-left: 5px;  /* Slightly indented for a cleaner look */
}

.google-maps-link a {
  color: #21A0A4;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9rem;  /* Slightly smaller text */
  display: inline-block;
  margin-top: 5px;  /* Adds space between the last info and the link */
}

.google-maps-link a:hover {
  color: #009BF4;
}
