.sidebar {
  flex: 0 0 100px; /* Increased from 80px to 160px */
  width: 100px;    /* Explicitly set the width to ensure consistency */
  height: 100vh;
  position: fixed;
  background-color: #ffffff;
  padding: 16px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}


.sidebar-logo {
  margin-bottom: 20px;
}

.sidebar-logo img {
  width: 40px; /* Make logo smaller */
  height: auto;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center icons and text */
  width: 100%;
}

.sidebar-menu .menu-item {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;
  text-decoration: none;
  color: #4f4f4f;
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  font-size: 0.9rem; /* Smaller text */
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 8px;
}

.sidebar-menu .menu-item:hover {
  color: #21A0A4;
  background-color: rgba(33, 160, 164, 0.1);
}

.sidebar-menu .menu-item img {
  width: 50px; /* Resize icons */
  height: 50px;
  margin-bottom: 5px; /* Space between icon and text */
}
