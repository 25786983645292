.content {
  flex: 1;
  margin-left: 100px;
  padding: 20px;
  overflow-y: auto;
  margin-top: 50px;  /* Space for navbar */
}

.custom-info-window {
    position: absolute;
    bottom: 20px;  /* Move to bottom */
    left: 120px;    /* Align to the left */
    background-color: white;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    max-width: 250px;
    font-family: 'League Spartan';
    font-size: 0.9rem;
    line-height: 1.4;
    position: fixed;  /* Fix the window in place */
}
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
}

.blinking-marker {
    animation: blink 1s infinite;
}

.custom-info-window h4 {
    font-size: 1.1rem;
    color: #21A0A4;
    margin-bottom: 10px;
}

.close-info-window {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.close-info-window img {
    width: 10px;
    height: 10px;
}

.google-maps-link {
    color: #21A0A4;
    margin:0px;
    text-decoration: none;
    font-weight: bold;
}

.google-maps-link:hover {
    text-decoration: underline;
}
