.app-container {
  display: flex;
  height: 100vh; /* This takes the full height of the viewport */
}

.sidebar {
  flex: 0 0 250px; /* Fixed width for sidebar */
  overflow-y: auto;
  position: fixed;
  height: 100vh;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  top: 65px;  /* Adjusted to leave space for the navbar */
  z-index: 90;  /* Lower than navbar to stay underneath */
}

.content {
  flex: 1;
  margin-left: 100px;
  padding: 20px;
  overflow-y: auto;
  margin-top: 20px;  /* Space for navbar */
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.map-legend {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.legend-item img {
  margin-right: 8px;
}
