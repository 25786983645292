body, .hydrant-history-container {
  font-family: 'League Spartan', sans-serif;
}

.hydrant-history-container {
  padding: 20px;
  background-color: #ffffff;
  font-family: 'League Spartan', sans-serif;
}

.hydrant-card, .hydrant-history-table, .hydrant-chart-card {
  background-color: #ffffff;
  border-radius: 8px;
  font-family: 'League Spartan', sans-serif;
  transition: transform 0.2s ease-in-out;
}

.hydrant-card:hover, .hydrant-history-table:hover, .hydrant-chart-card:hover {
  transform: translateY(-5px);
}

.hydrant-title {
  color: #21A0A4;
  font-family: 'League Spartan', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.divider {
  margin-bottom: 16px;
  font-family: 'League Spartan', sans-serif;
  background-color: #21A0A4;
}

.hydrant-detail {
  font-family: 'League Spartan', sans-serif;
  align-items: baseline;
  margin-bottom: 10px;
  margin-top: 10px;
  color: rgb(79, 79, 79) !important;
  font-size: 0.9rem !important;
}

.hydrant-detail Typography {
  margin-left: 10px;
  font-family: 'League Spartan', sans-serif;
  color: #4f4f4f;
}

.table-title {
  color: #4f4f4f;
  font-weight: bold;
  padding: 16px;
  font-family: 'League Spartan', sans-serif;
}

.table-title-chart {
  color: #4f4f4f;
  font-weight: bold;
  font-family: 'League Spartan', sans-serif;
}

.table-header {
  background-color: #21A0A4;
  color: #ffffff;
  font-family: 'League Spartan', sans-serif;
  font-size: 1.1rem;
}

.MuiTableRow-root:hover {
  background-color: rgba(33, 160, 164, 0.1);
}
.hydrant-chart-card text {
  font-family: 'League Spartan', sans-serif;
}

